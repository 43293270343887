import React from "react"
import { graphql } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { greyDarker, containerFixed } from "../../constants/cssVariables"
import Section from "../../components/Section/Section"
import PageHeader from "../../components/PageHeader/PageHeader"
import SimplePageImage from "../../assets/vector/vector-tech-1.svg"
import "./SimplePage.css"

export const query = graphql`
    query($slug: String!) {
        simpleTempData: allContentfulSimplePage(
            filter: { slug: { eq: $slug } }
        ) {
            edges {
                node {
                    seoTitle
                    seoDescription
                    description {
                        json
                    }
                }
            }
        }
    }
`

const SimpleTemplate = ({ location, data: { simpleTempData } }) => {
    const { seoTitle, seoDescription } = simpleTempData.edges[0].node

    const H1 = ({ children }) => (
        <h1 className="SimplePage-title">{children}</h1>
    )
    const H2 = ({ children }) => (
        <h2 className="SimplePage-subtitle">{children}</h2>
    )
    const Text = ({ children }) => <p className="SimplePage-text">{children}</p>

    const UL = ({ children }) => <ul className="SimplePage-list">{children}</ul>

    const OL = ({ children }) => <ol className="SimplePage-list">{children}</ol>

    const Link = ({ node, children }) => {
        return (
            <a href={children.data.uri} className="SimplePage-link">
                {children.content[0].value}
            </a>
        )
    }

    const Image = ({ children }) => (
        <img
            src={children.data.target.fields.file["en-US"].url}
            alt={children.data.target.fields.title["en-US"]}
            className="SimplePage-image"
        />
    )

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
            [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
            [BLOCKS.OL_LIST]: (node, children) => <OL>{children}</OL>,
            [INLINES.HYPERLINK]: (node, children) => <Link>{node}</Link>,
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image>{node}</Image>,
        },
    }

    return (
        <Layout location={location}>
            <SEO title={seoTitle} description={seoDescription} />
            <Section bgColor={greyDarker} container={containerFixed}>
                <div className="SimplePage">
                    <PageHeader PageHeaderTitle={"Back to Homepage"} />
                    <div className="SimplePage-inner">
                        <div className="SimplePage-col-wide">
                            {documentToReactComponents(
                                simpleTempData.edges[0].node.description.json,
                                options
                            )}
                        </div>
                        <div className="SimplePage-col-narrow">
                            <img
                                src={SimplePageImage}
                                alt=""
                                role="presentation"
                                className="SimplePage-default-image"
                            />
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    )
}

export default SimpleTemplate
