import React from "react"
import { Link } from "gatsby"
import "./PageHeader.css"

const PageHeader = ({ PageHeaderTitle, PageHeaderUrl }) => {
    return (
        <div className="PageHeader">
            <Link
                to={PageHeaderUrl ? PageHeaderUrl : "/"}
                className="PageHeader-title"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 477.175 477.175"
                    width="512"
                    height="512"
                    className="PageHeader-icon"
                >
                    <path
                        d="M145.188 238.575l215.5-215.5c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-225.1 225.1c-5.3 5.3-5.3 13.8 0 19.1l225.1 225c2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4c5.3-5.3 5.3-13.8 0-19.1l-215.4-215.5z"
                        data-original="#000000"
                        data-old_color="#000000"
                        fill="#6A81A3"
                    />
                </svg>
                {PageHeaderTitle}
            </Link>
        </div>
    )
}

export default PageHeader
